<template>
<div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm pb-2">
                                    <div class="nk-block-between">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title page-title">Invoices</h3>
                                            <div class="nk-block-des text-soft">
                                                <p>You have total {{invoices.length}} invoices.</p>
                                            </div>
                                        </div><!-- .nk-block-head-content -->
                                        <div class="nk-block-head-content">
                                            <div class="toggle-wrap nk-block-tools-toggle">
                                                <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                                                <div class="toggle-expand-content" data-content="pageMenu">
                                                    <ul class="nk-block-tools g-3">
                                                        <li><a href="#" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li>
                                                    </ul>
                                                </div>
                                            </div><!-- .toggle-wrap -->
                                        </div><!-- .nk-block-head-content -->
                                    </div><!-- .nk-block-between -->
                                </div><!-- .nk-block-head -->
                                <div class="nk-block">
                                    <div class="card p-2 card-stretch">
                                        <div class="card-inner-group">
                                            <div class="card-inner p-0">
                                                <div class="nk-tb-list nk-tb-ulist is-compact">
                                                     <vue-good-table v-if="invoices"
          :columns="columns"
          :rows="invoices"
          styleClass="vgt-table condensed"
          :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
                              :sort-options="{ enabled: true,}"
        >
         <template slot="table-row" slot-scope="props" >
          <span class="vgt-muted"  v-if="props.column.field == 'total'" >
            {{props.formattedRow[props.column.field]}}
          </span>
          <div v-else-if="props.column.field == 'actions'">
            <span>This is an action</span>
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
         </template>
        </vue-good-table>
                                                </div><!-- .nk-tb-list -->
                                            </div><!-- .card-inner -->
                                            <div class="card-inner">
                                                <ul class="pagination justify-content-center justify-content-md-start">
                                                    <li class="page-item"><a class="page-link" href="#">Prev</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><span class="page-link"><em class="icon ni ni-more-h"></em></span></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                                </ul><!-- .pagination -->
                                            </div><!-- .card-inner -->
                                        </div><!-- .card-inner-group -->
                                    </div><!-- .card -->
                                </div><!-- .nk-block -->
                            </div>
      
</template>

<script>
export default {
 data: function () {
    return {
      invoices: [{id:1, reference: 'fg gfd g kk', createdDate: '26/02/1983',total: '19.5' },
      {id:2, reference: 'adfdf sg gfgd', createdDate: '22/02/1983',total: '22.5', },
      {id:3, reference: 'dddgfg fgdf g sads', createdDate: '26/02/1983',total: '19.5', }],
      columns: [
        { label: 'id', field: 'id' },
        { label: 'Reference', field: 'reference' },
        { label: 'Total', field: 'total' },
        { label: 'Created', field: 'createdDate' },
        { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
      ],
    }
  },
  created() {
      this.getView()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/billing')
        .then(() => {
        })
        .catch(() => {
        })
    },
  }
}
</script>

<style> 
</style>